import { createRouter, createWebHistory } from "vue-router";

import Guard from '../services/middleware.vue'

const routes = [
  {
    path: '/convite/create',
    name: 'Criar convite',
    component: () => import("@/views/invites/create.vue"),
  },
  {
    path: '/convite/:slug',
    name: 'Convite',
    component: () => import("@/views/invites/index.vue"),
  },
  {
    path: '/',
    name: 'Modelos Incríveis',
    component: () => import("@/views/home/index.vue"),
    children: [
      {
        path: '',
        name: 'Todos os convites',
        component: () => import("@/views/home/invites.vue"),
      },
      {
        path: '/todos-os-modelos',
        name: 'Todos os modelos',
        component: () => import("@/views/home/invites.vue"),
      },
      {
        path: '/aniversario',
        name: 'Aniversário',
        component: () => import("@/views/home/invites.vue"),
      },
      {
        path: '/casamento',
        name: 'Casamento',
        component: () => import("@/views/home/invites.vue"),
      },
      {
        path: '/cha-revelacao',
        name: 'Chá revelação',
        component: () => import("@/views/home/invites.vue"),
      },
      {
        path: '/cha-rifa',
        name: 'Chá rifa',
        component: () => import("@/views/home/invites.vue"),
      },
      {
        path: '/cha-de-bebe',
        name: 'Chá de bebê',
        component: () => import("@/views/home/invites.vue"),
      },
      {
        path: '/aniversario-infantil',
        name: 'Aniversário infantil',
        component: () => import("@/views/home/invites.vue"),
      },

    ]
  },
  {
    path: "/admin",
    name: "CRM Dashboard",
    redirect: "/admin/crm",
    component: () => import("@/Layout/Admin/index.vue"),
    beforeEnter: Guard.auth,
    children: [
      {
        path: "crm",
        name: "crm",
        component: () => import("@/views/admin/home/admin.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("@/views/admin/users/users.vue"),
      },
      {
        path: "invites/:userId",
        name: "invites",
        component: () => import("@/views/admin/invites/index.vue"),
      },
    ],
  },
  // Adicione mais rotas aqui, se necessário
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {

  const routePath = to.path;

  let pageTitle = "Convite";

  if (routePath.startsWith("/convite")) {

    // Extrair o slug da rota
    const slug = routePath.split('/')[2];

    // Remover caracteres especiais e formatar o slug
    const formattedSlug = slug
      .replace(/[^a-zA-Z0-9-]/g, " ") // Remover caracteres especiais
      .split("-") // Dividir em palavras separadas por '-'
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar a primeira letra de cada palavra
      .join(" "); // Juntar as palavras com espaço

    let subRouteTitle = "";
    if (routePath.includes("/convite")) {
      subRouteTitle = "Editando Convite de";
    }


    // Adicionar o título personalizado à página
    pageTitle = `${subRouteTitle} ${formattedSlug} | Convitede`;
  }

  document.title = pageTitle;
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
